"use client";
import ToggleSidebarButton from "./ToggleSidebarButton";
import Nav from "./Nav";
import { useState } from "react";
import Sidebar from "./sidebar/Sidebar";

export default function Navbar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className='bg-black'>
      {isSidebarOpen && <Sidebar />}

      <header className='  flex items-center  inset-0 z-30 px-0 py-3 md:py-5 md:px-0 justify-between relative  '>
        {" "}
        <ToggleSidebarButton
          toggleSidebar={toggleSidebar}
          isSidebarOpen={isSidebarOpen}
          className='toggle-sidebar-button mx-4'
        />
        <a href='/' className='logo-link px-auto md:px-6   '>
          <img
            src='https://images.versx.io/public/logo.svg'
            className='w-32  h-auto my-auto'
          />{" "}
        </a>
        <div className='hidden md:flex'>
          <Nav />
        </div>{" "}
        <div className=' px-4 md:px-8   w-52 py-[18px]  flex'></div>
      </header>
    </div>
  );
}
