/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import "./App.css";

import Navbar from "./components/Navbar";
import MainPage from "./MainPage";

function App() {
  return (
    <div className='  relative-positioning'>
      <div className='overlay'></div>
      <Navbar />{" "}
      <iframe
        src='https://sub-menu-cmc.vercel.app'
        style={{
          zIndex: "1",
          position: "relative",
          width: "100%", // Full width
          height: "100%", // Specify the height
          border: "none", // No border
          overflow: "hidden", // Hide scrollbars
        }}
      />{" "}
      <div className='  md:px-36 mx-auto   px-5 justify-start    relative  '>
        <h1 className=' my_heading text-left  '>Multi-Chain Staking</h1>
        <div className='flex my-2 items-center    gap-2'>
          <p className='text-white/60 text-xs'>Supported Blockchains</p>
          <div className='flex items-center gap-2 md:gap-4'>
            <img className='rounded-full   w-5  h-5' src='/chains/1.svg' />{" "}
            <img className='rounded-full  w-5  h-5' src='/chains/2.svg' />{" "}
            <img className='rounded-full   w-5  h-5' src='/chains/3.svg' />{" "}
            <img className='rounded-full     w-5  h-5' src='/chains/4.svg' />{" "}
            <img className='rounded-full    w-5  h-5' src='/chains/5.svg' />{" "}
            <img className='rounded-full    w-5  h-5' src='/chains/7.svg' />
          </div>
        </div>{" "}
      </div>
      <div className='  px-2 md:px-36  mx-auto relative z-10 flex  flex-col min-h-screen'>
        <MainPage />
      </div>
      <iframe
        src='https://sub-menu-cmc.vercel.app/footer'
        style={{
          zIndex: "1",
          position: "relative",
          width: "100%", // Full width
          height: "100vh", // Specify the height
          border: "none", // No border
          overflow: "hidden", // Hide scrollbars
        }}
      />{" "}
    </div>
  );
}
export default App;
