// AuthContext.js

import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext(null);
///@ts-ignore
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = (password: any) => {
    if (password === "znjGV-uGA@%WD{s") {
      setIsAuthenticated(true);
      return true;
    }
    return false;
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  return (
    //@ts-ignore
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
