// router.js

import { createBrowserRouter } from "react-router-dom";
import Login from "./Login";
import Home from "./Home";
import RequireAuth from "./RequireAuth";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    ),
  },
]);

export default router;
