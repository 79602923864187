import React from "react";
import { CircleX, MenuIcon } from "lucide-react";

// Extending the button attributes to include the custom toggleSidebar prop
interface ToggleSidebarButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  toggleSidebar: () => void; // Custom function to toggle sidebar
  isSidebarOpen: boolean; // New prop to determine icon state
}

export default function ToggleSidebarButton({
  toggleSidebar,
  isSidebarOpen,

  className,
}: ToggleSidebarButtonProps) {
  // Use the extended interface here
  return (
    <button
      className={className}
      onClick={() => toggleSidebar()} // Use the custom function on click
    >
      {isSidebarOpen ? (
        <CircleX color='#DEDF1B' /> // Show close icon when sidebar is open
      ) : (
        <MenuIcon color='#DEDF1B' /> // Show menu icon when sidebar is closed
      )}{" "}
    </button>
  );
}
