import React from "react";

interface AccordionProps {
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  isOpen,
  onToggle,
  children,
}) => {
  return (
    <div className='border border-opacity-50 border-vyellow rounded-md mb-4'>
      <div
        className='flex justify-between items-center p-4 cursor-pointer'
        onClick={onToggle}
      >
        <h2 className='text-lg font-medium'>{title}</h2>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className={`h-6 w-6 transition-transform transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d={isOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}
          />
        </svg>
      </div>
      {isOpen && (
        <div className='p-4 mb-4 border-t border-vyellow border-opacity-20'>
          {children}
        </div>
      )}
    </div>
  );
};

export default Accordion;
