import { useState, useRef } from "react";
import { menuItemsData } from "./MenuItemsData";
import AppMenu from "./AppMenu";

export default function Nav() {
  //@ts-ignore
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [arrowPosition, setArrowPosition] = useState("50%");

  //@ts-ignore
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);

  const menuListRef = useRef(null);
  const menuItemRefs = useRef([]);

  //@ts-ignore

  const calculateArrowPosition = (index) => {
    const menuItem = menuItemRefs.current[index];
    if (!menuItem || !menuListRef.current) {
      console.log("Menu item or menu list not found");
      return "50%";
    }
    //@ts-ignore

    const menuRect = menuItem.getBoundingClientRect();
    //@ts-ignore

    const menuListRect = menuListRef.current.getBoundingClientRect();
    const menuItemCenter =
      menuRect.left + menuRect.width / 2 - menuListRect.left;
    setArrowPosition(`${menuItemCenter}px`);
  };
  //@ts-ignore

  const handleMouseEnter = (menuTitle, index) => {
    calculateArrowPosition(index);
    setActiveSubMenu(menuTitle);
    setActiveMenuIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveSubMenu(null);
    setActiveMenuIndex(null);
  };

  //@ts-ignore

  const renderSubMenu = (title) => {
    const submenuItems =
      menuItemsData.find((item) => item.title === title)?.submenu || [];

    return (
      submenuItems.length > 0 && (
        <div className='submenu_block' style={{ display: "block" }}>
          <div
            className='submenu'
            //@ts-ignore
            style={{ "--arrow-position": arrowPosition }}
          >
            {submenuItems.map((item, index) => (
              <div
                key={index}
                style={{ padding: "1% 2%", margin: "8% 0", width: "300px" }}
              >
                {item?.title === "Farming" ? (
                  <a
                    href={item.url}
                    style={{
                      textAlign: "left",
                      cursor: "default",
                      background: "#131313",
                      padding: "4%",
                    }}
                  >
                    {item.title}{" "}
                    <span style={{ fontSize: "8px" }}>Coming Soon</span>
                    <br></br>
                    <span className='sub_info' style={{ textAlign: "left" }}>
                      {" "}
                      {item.info}
                    </span>
                  </a>
                ) : (
                  <a
                    href={item.url}
                    style={{ textAlign: "left", padding: "4%" }}
                  >
                    {item.title}
                    <br></br>
                    <span className='sub_info' style={{ textAlign: "left" }}>
                      {" "}
                      {item.info}
                    </span>
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      )
    );
  };

  return (
    <nav className='nav-bg'>
      <div className='container'>
        <div>
          <div
            className={`menu-wrapper flex items-center ${
              isMenuOpen ? "mobile-visible" : ""
            }`}
          >
            <ul className='menu-list' ref={menuListRef}>
              {menuItemsData.map(
                (item, index) =>
                  index < 4 && (
                    <div
                      key={index}
                      onMouseEnter={() => handleMouseEnter(item.title, index)}
                      onMouseLeave={handleMouseLeave} //@ts-ignore
                      ref={(el) => (menuItemRefs.current[index] = el)}
                    >
                      <li
                        className={`menu-item flex gap-2 ${
                          activeSubMenu === item.title ? "active" : ""
                        }`}
                      >
                        <a href={`${item.url}`}>{item.title}</a>
                        {item.submenu && item.submenu.length > 0 && (
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='sc-dhaItB fhVrZj' // Note: Changed 'class' to 'className' for JSX
                          >
                            <path
                              d='M8.71005 11.71L11.3001 14.3C11.6901 14.69 12.3201 14.69 12.7101 14.3L15.3001 11.71C15.9301 11.08 15.4801 10 14.5901 10H9.41005C8.52005 10 8.08005 11.08 8.71005 11.71Z'
                              fill='#dedf1b'
                            ></path>
                          </svg>
                        )}
                      </li>
                      {activeSubMenu === item.title &&
                        renderSubMenu(item.title)}
                    </div>
                  )
              )}
              <AppMenu />
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
