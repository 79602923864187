// Login.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

function Login() {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    //@ts-ignore
    if (auth.login(password)) {
      navigate("/");
    } else {
      setError("Invalid Password");
    }
  };

  return (
    <div className='  relative-positioning'>
      <div className='overlay'></div>{" "}
      <div className='flex justify-center items-center h-screen '>
        <div className='w-full max-w-xs'>
          <div className='bg-black border border-1 border-[#dedf1b] z-10 relative  p-8 rounded-lg shadow-lg'>
            <h2 className='text-white text-lg font-bold mb-6'>
              Authentication Required
            </h2>
            <form onSubmit={handleSubmit} className='flex flex-col'>
              <label className='block text-left text-gray-300 text-sm font-bold mb-2'>
                Password
              </label>
              <input
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className='mb-4 p-3 rounded bg-white text-black'
                placeholder='Enter password'
              />
              <button
                type='submit'
                className='bg-black hover:bg-[#dedf1b] border border-[#dedf1b] hover:text-black text-white font-bold py-2 px-4 rounded'
              >
                Log in
              </button>
              {error && (
                <p className='text-red-500 text-xs italic mt-4'>{error}</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
