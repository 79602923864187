import { useState } from "react";
import { menuItemsData } from "./MenuItemsData";

const AppMenu = () => {
  const white_svg = "https://images.versx.io/public/white.svg";
  const black_svg = "https://images.versx.io/public/black.svg";
  const [showMenus, setShowMenus] = useState(false);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);

  // Adjusted to control hover over the entire component
  const handleMouseEnter = () => {
    setShowMenus(true);
  };

  const handleMouseLeave = () => {
    setShowMenus(false);
  };

  return (
    <>
      <div style={{ position: "relative" }} onMouseLeave={handleMouseLeave}>
        <div onMouseEnter={handleMouseEnter}>
          <img
            src={showMenus ? white_svg : black_svg}
            alt='Logo'
            style={{
              transition: "all 0.3s ease",
              cursor: "pointer",
              height: "1.75rem",
              width: "auto",
            }}
          />
        </div>
        {showMenus && (
          <ul className='app_menu'>
            {menuItemsData.map(
              (menu, index) =>
                menu?.title !== "Lottery" &&
                index > 3 && (
                  <li
                    className='sub-menu-item'
                    key={index}
                    onMouseEnter={() =>
                      menu.submenu?.length > 0
                        ? setActiveMenu(menu.title)
                        : null
                    }
                    onMouseLeave={() => setActiveMenu(null)}
                  >
                    {menu?.title === "Exchange" ? (
                      <a
                        className='sub-menu-item_a'
                        href={menu.url}
                        style={{
                          textAlign: "left",
                          cursor: "default",
                          background: "black",
                          padding: "4%",
                        }}
                      >
                        {menu?.title} <br style={{ marginTop: "-5px" }}></br>
                        <span style={{ fontSize: "8px" }}>Coming Soon</span>
                      </a>
                    ) : (
                      <a className='sub-menu-item_a' href={menu.url}>
                        {menu.title}
                      </a>
                    )}
                    {activeMenu === menu.title && menu.submenu?.length > 0 && (
                      <div className='app_submenu_block'>
                        <div>
                          <ul className='app_submenu'>
                            {menu?.submenu.map(
                              (subItem, subIndex) =>
                                subItem?.title?.length > 0 && (
                                  <li
                                    key={subIndex}
                                    className={` ${
                                      activeMenu === subItem.title
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <a
                                      style={{ textAlign: "left" }}
                                      href={subItem.url}
                                    >
                                      {subItem.title}
                                      <br></br>
                                      <span className='sub_info '>
                                        {" "}
                                        {subItem.info}
                                      </span>{" "}
                                    </a>
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      </div>
                    )}
                  </li>
                )
            )}
          </ul>
        )}
      </div>{" "}
    </>
  );
};

export default AppMenu;
